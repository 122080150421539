<template>
  <div>
    <b-row>
      <b-col md="12">
        <b-card>
          <b-card>
            <!-- input search -->
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <div class="d-flex align-items-center">
                  <label class="mr-1">Recherche
                  </label>
                  <b-form-input
                    v-model="searchTerm"
                    placeholder="Recherche"
                    type="text"
                    class="d-inline-block"
                  />
                </div>
              </b-form-group>
              <!-- primary -->
              <b-dropdown
                id="dropdown-1"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                text="Exporter"
                variant="primary"
                class="ml-2 btn_export"
              >
                <b-dropdown-item @click="exportCSV">
                  CSV
                </b-dropdown-item>
                <b-dropdown-item @click="exportExcel">
                  MS-Excel
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <vue-good-table
              id="list"
              ref="myTable"
              :columns="columns"
              :rows="pdvs"
              :rtl="direction"
              :search-options="{
                enabled: true,
                externalQuery: searchTerm }"
              :pagination-options="{
                enabled: true,
                perPage:pageLength
              }"
            >
              <template
                slot="table-row"
                slot-scope="props"
              >

                <!-- Column: Status -->
                <span v-if="props.column.field === 'status'">
                  <b-badge :variant="statusVariant(props.row.status)">
                    {{ props.row.status }}
                  </b-badge>
                </span>

                <!-- Column: Action -->
                <span v-else-if="props.column.field === 'action'">
                  <b-button-group>
                    <b-button
                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                      variant="outline-primary"
                      :to="{ name: 'points-de-vente-details', params: { id: props.row.id } }"
                    >
                      Voir
                    </b-button>
<!--                    <b-button-->
<!--                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                      variant="outline-primary"-->
<!--                      @click="handleUpdate(props.row)"-->
<!--                    >-->
<!--                      Modifier-->
<!--                    </b-button>-->
<!--                    <b-button-->
<!--                      v-ripple.400="'rgba(113, 102, 240, 0.15)'"-->
<!--                      variant="outline-danger"-->
<!--                      @click="handleDelete(props.row.id)"-->
<!--                    >-->
<!--                      Supprimer-->
<!--                    </b-button>-->
                  </b-button-group>

                </span>

                <!-- Column: Common -->
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>

              <!-- pagination -->
              <template
                slot="pagination-bottom"
                slot-scope="props"
              >
                <div class="d-flex justify-content-between flex-wrap">
                  <div class="d-flex align-items-center mb-0 mt-1">
                    <span class="text-nowrap">
                      Affichage de 1 à
                    </span>
                    <b-form-select
                      v-model="pageLength"
                      :options="['3','5','10']"
                      class="mx-1"
                      @input="(value)=>props.perPageChanged({currentPerPage:value})"
                    />
                    <span class="text-nowrap "> sur {{ props.total }} élements </span>
                  </div>
                  <div>
                    <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="(value)=>props.pageChanged({currentPage:value})"
                    >
                      <template #prev-text>
                        <feather-icon
                          icon="ChevronLeftIcon"
                          size="18"
                        />
                      </template>
                      <template #next-text>
                        <feather-icon
                          icon="ChevronRightIcon"
                          size="18"
                        />
                      </template>
                    </b-pagination>
                  </div>
                </div>
              </template>
            </vue-good-table>
          </b-card>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BBadge, BButton, BButtonGroup,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BPagination,
  BRow,
} from 'bootstrap-vue'
import VueGoodTable from 'vue-good-table/src/components/Table.vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import htmlToPaper from 'vue-html-to-paper'
import vSelect from 'vue-select'
import { API_URL } from '@/helpers/global-scops'
import showToast from '@/helpers/methodes'

export default {
  name: 'PDVListeMainVue',
  components: {
    BButtonGroup,
    BButton,
    BBadge,
    VueGoodTable,
    BCard,
    BCol,
    BRow,
    BDropdown,
    BFormInput,
    BDropdownItem,
    BFormGroup,
    BFormSelect,
    BPagination,
    vSelect,
  },
  directives: {
    Ripple,
    htmlToPaper,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    commercialId: {
      type: String,
      default: null,
    },
    pdvs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      rows: [],
      commercials: [
      ],
      types: [
      ],
      PDV: {
        nom: '',
        telephone: '',
        type: '',
        commercial: '',
        arrondissement: '',
        arrondissementState: null,
        nomState: null,
        commercialState: null,
        telephoneState: null,
        typeState: null,
      },
      pageLength: 10,
      searchTerm: '',
      columns: [
        {
          label: 'Nom',
          field: 'nom',
        },
        {
          label: 'Type',
          field: 'type.nom',
        },
        {
          label: 'Commercial',
          field: 'commercial_r.full_name',
        },
        {
          label: 'Region',
          field: 'region',
        },
        {
          label: 'Zone',
          field: 'zone',
        },
        {
          label: 'Secteurs',
          field: 'secteur',
        },
        {
          label: 'Actions',
          field: 'action',
        },
      ],
      arrondissements: [
      ],
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        Current: 'light-primary',
        Professional: 'light-success',
        Rejected: 'light-danger',
        Resigned: 'light-warning',
        Applied: 'light-info',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
  },
  created() {
    // this.test()
    // this.getCommercials()
    // this.getTypes()
    // this.getPDV()
    // this.getArrondissements()
  },
  methods: {
    test() {
      this.$http.get(`${API_URL}commes/?zone=2`)
        .then(response => {
          console.log(response.data)
        })
        .catch(error => {
          console.log(error)
        })
    },
    exportExcel() {
    },
    exportCSV() {
      const header = this.columns.map(column => column.label)
      const data = this.rows.map(row => this.columns.map(column => row[column.field]))
      let csvData = `${header.join(',')}\n`
      csvData += data.map(row => row.join(',')).join('\n')
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' })
      const url = URL.createObjectURL(blob)
      const link = document.createElement('a')
      link.setAttribute('href', url)
      link.setAttribute('download', `${this.filename}.csv`)
      link.style.visibility = 'hidden'
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },

    checkFormValidity() {
      this.PDV.typeState = !!this.PDV.type
      this.PDV.commercialState = !!this.PDV.commercial
      this.PDV.nomState = this.PDV.nom.length > 0
      this.PDV.telephoneState = this.PDV.telephone.length > 0
      return this.PDV.nomState
        && this.PDV.typeState
        && this.PDV.telephoneState
        && this.PDV.commercialState
    },
    resetModal() {
      this.PDV.nom = ''
      this.PDV.type = ''
      this.PDV.commercial = ''
      this.PDV.telephone = ''
      this.PDV.arrondissement = ''
      this.PDV.typeState = null
      this.PDV.nomState = null
      this.PDV.telephoneState = null
      this.PDV.commercialState = null
      this.PDV.arrondissementState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.handleSubmit()
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      this.savePDV()
    },
    getCommercials() {
      this.$http.get(`${API_URL}commercials/`)
        .then(response => {
          this.commercials = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getTypes() {
      this.$http.get(`${API_URL}type_pdvs/`)
        .then(response => {
          this.types = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    getArrondissements() {
      this.$http.get(`${API_URL}arrondissements/`)
        .then(response => {
          this.arrondissements = response.data
        })
        .catch(error => {
          console.log(error)
        })
    },
    savePDV() {
      if (!this.PDV.id && this.PDV.id == null) {
        this.$http.post(`${API_URL}point_de_ventes/`, this.PDV).then(response => {
          console.log(response.data)
          showToast(this, 'info', 'Ajout', 'Element ajouté')
          this.getPDV()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['PDV-modal'].toggle('#toggle-btn')
          })
        }).catch(error => {
          console.log(error)
        })
      } else {
        this.$http.put(`${API_URL}point_de_ventes/${this.PDV.id}/`, this.PDV).then(response => {
          console.log(response.data)
          showToast(this, 'primary', 'Modification', 'Element modifié')
          this.getPDV()
          // Hide the modal manually
          this.$nextTick(() => {
            this.$refs['PDV-modal'].toggle('#toggle-btn')
          })
        }).catch(error => {
          console.log(error)
        })
      }
    },
    handleDelete(id) {
      // eslint-disable-next-line no-restricted-globals,no-alert
      if (confirm('Êtes-vous sûr de vouloir supprimer cet élément ?')) {
        this.$http.delete(`${API_URL}point_de_ventes/${id}/`).then(response => {
          console.log(response.data)
          showToast(this, 'danger', 'Suppression', 'Element supprimé')

          this.getPDV()
        }).catch(error => {
          console.log(error)
        })
      } else {
        // Annuler la suppression ici
      }
    },
    handleUpdate(item) {
      console.log(item)
      this.PDV.id = item.id
      this.PDV.nom = item.nom
      this.PDV.commercial = item.commercial_r
      this.PDV.arrondissement = item.arrondissement_r
      this.PDV.type = item.type
      this.PDV.telephone = item.contact

      this.$refs['PDV-modal'].toggle('#toggle-btn')
    },
  },
}
</script>

<style scoped>

</style>
